import { Dispatch, FC, SetStateAction, useState } from 'react'
import { ButtonWrapper, FormWrapper } from '../style'
import { Form } from '@components/core/forms'
import { Input } from '@components/core/forms/input'
import { ButtonSize, PrimaryButton } from '@components/core/buttons'
import { smsFinish } from '@lib/api'

interface FinishLoginProps {
  methodId: string
  requiresSetup: boolean
  setAuthChecked: Dispatch<SetStateAction<boolean>>
  setLoginComplete: Dispatch<SetStateAction<boolean>>
  setOpen: Dispatch<SetStateAction<boolean>>
  setShouldFetch: Dispatch<SetStateAction<boolean>>
}

const FinishLogin: FC<FinishLoginProps> = ({
  methodId,
  requiresSetup,
  setAuthChecked,
  setLoginComplete,
  setOpen,
  setShouldFetch,
}) => {
  const [code, setCode] = useState<string>('')

  const finishSmsLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const authMethodId = methodId || localStorage.getItem('methodId')

      const { user } = await smsFinish(code, authMethodId)

      if (user && user.id) {
        localStorage.removeItem('methodId')

        setAuthChecked(false)
        setLoginComplete(true)
        setShouldFetch(true)

        if (!requiresSetup) {
          setOpen(false)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <p>Enter the verification code sent to your phone via SMS.</p>

      <FormWrapper>
        <Form onSubmit={finishSmsLogin}>
          <Input
            onChange={(e) => setCode(e.target.value)}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            value={code}
          />

          <ButtonWrapper>
            <PrimaryButton size={ButtonSize.Large} type="submit">
              Verify
            </PrimaryButton>
          </ButtonWrapper>
        </Form>
      </FormWrapper>
    </>
  )
}

export default FinishLogin
