import { ContentWrapper } from './style'
import { getLastKnownLocation } from '@lib/voucci/client'
import { getMe } from '@lib/api/v1/users'
import { logout } from '@lib/api/v1/auth/logout'
import { ThemeProvider } from '@emotion/react'
import { User } from '@models/users'
import { VoucciStateContextProvider } from '@context/state'
import Footer from './footer'
import Header from './header'
import LocationSwitcher from '@components/page/location-switcher'
import LoginModal from '@components/auth/login'
import React, { FC, useEffect, useState } from 'react'
import theme from '@lib/theme'
import type { VoucciState } from 'types'
import ProfileEditor from './profile-editor'
import { Category } from '@models/categories'
import { getAllCategories } from '@lib/api'

interface PageProps {
  categories?: Category[]
  children?: React.ReactNode | React.ReactNode[] | null
  user?: User
}

const Page: FC<PageProps> = ({
  categories: initialCategories,
  children,
  user,
}) => {
  const [authChecked, setAuthChecked] = useState<boolean>(false)
  const [categories, setCategories] = useState<Category[]>(
    initialCategories || [],
  )
  const [initialized, setInitialized] = useState<boolean>(false)
  const [locationSwitcherOpen, setLocationSwitcherOpen] =
    useState<boolean>(false)
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false)
  const [profileEditorVisible, setProfileEditorVisible] =
    useState<boolean>(false)
  const [shouldFetch, setShouldFetch] = useState<boolean>(true)
  const [state, setState] = useState<VoucciState>({})

  const handleLogout = async (): Promise<void> => {
    const loggedOut = await logout()

    if (loggedOut) {
      setShouldFetch(false)
      setState({
        ...state,
        user: null,
      })
    }
  }

  const modifyState = (newState: Partial<VoucciState>) => {
    setState({
      ...state,
      ...newState,
    })
  }

  const updateCategories = async () => {
    if (!categories.length) {
      const categories = await getAllCategories()

      setCategories(categories)
    }
  }

  const updateState = async () => {
    let location = state.location
    let user = state.user

    if (!location) {
      location = await getLastKnownLocation()
    }

    if (!user) {
      user = await getMe()
    }

    setAuthChecked(true)
    setShouldFetch(false)

    setState({
      ...state,
      location,
      user,
    })
  }

  useEffect(() => {
    if (!authChecked && shouldFetch) {
      updateState()
    }
  }, [authChecked, shouldFetch])

  useEffect(() => {
    if (
      initialCategories &&
      initialCategories.length &&
      categories &&
      !categories.length
    ) {
      const categories = initialCategories.sort((a, b) =>
        a.name.localeCompare(b.name),
      )

      setCategories(categories)
    } else if (initialCategories && !initialCategories.length) {
      updateCategories()
    }
  }, [categories, initialCategories])

  useEffect(() => {
    if (state && !initialized) {
      setInitialized(true)
      updateState()
    }
  }, [initialized, state])

  useEffect(() => {
    if (user) {
      setState({
        ...state,
        user,
      })
    }
  }, [user])

  return (
    <VoucciStateContextProvider value={state}>
      <ThemeProvider theme={theme}>
        <Header
          logout={handleLogout}
          setLocationSwitcherOpen={setLocationSwitcherOpen}
          setLoginModalOpen={setLoginModalOpen}
          setProfileEditorVisible={setProfileEditorVisible}
        />

        <ContentWrapper>{children}</ContentWrapper>

        <Footer />

        {locationSwitcherOpen && (
          <LocationSwitcher
            setOpen={setLocationSwitcherOpen}
            setState={modifyState}
          />
        )}

        {loginModalOpen && (
          <LoginModal
            setAuthChecked={setAuthChecked}
            setOpen={setLoginModalOpen}
            setShouldFetch={setShouldFetch}
          />
        )}

        {profileEditorVisible && (
          <ProfileEditor
            categories={categories}
            logout={handleLogout}
            setOpen={setProfileEditorVisible}
            setState={modifyState}
          />
        )}
      </ThemeProvider>
    </VoucciStateContextProvider>
  )
}

export default Page
