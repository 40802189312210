import facepaint from 'facepaint'

type BreakpointsType = number[]

export const breakpoints: BreakpointsType = [576, 768, 1024, 1200]

export const breakpointQueries = breakpoints.map(
  (bp) => `@media (min-width: ${bp}px)`,
)

export const mediaQueryStrings = {
  mobile: `@media (max-width: ${breakpoints[1] - 1}px)`,
  tablet: `@media (min-width: ${breakpoints[1]}px) and (max-width: ${
    breakpoints[2] - 1
  }px)`,
  desktop: `@media (min-width: ${breakpoints[2]}px)`,
}

export const mediaQueries = facepaint(breakpointQueries)
