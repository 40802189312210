import Modal from '@components/core/modal'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { ButtonWrapper, FormWrapper, LoginWrapper } from './style'
import { Form } from '@components/core/forms'
import { Input } from '@components/core/forms/input'
import { ButtonSize, PrimaryButton } from '@components/core/buttons'
import { getPhoneCode } from '@lib/device'
import { smsFinish, smsStart } from '@lib/api'
import StartLogin from './start'
import FinishLogin from './finish'
import AccountSetup from './account-setup'
import { User } from '@models/users'

interface LoginModalProps {
  setAuthChecked: Dispatch<SetStateAction<boolean>>
  setOpen: Dispatch<SetStateAction<boolean>>
  setShouldFetch: Dispatch<SetStateAction<boolean>>
}

const LoginModal: FC<LoginModalProps> = ({
  setAuthChecked,
  setOpen,
  setShouldFetch,
}) => {
  const [loginComplete, setLoginComplete] = useState<boolean>(false)
  const [methodId, setMethodId] = useState<string>('')
  const [numberSubmitted, setNumberSubmitted] = useState<boolean>(false)
  const [requiresSetup, setRequiresSetup] = useState<boolean>(false)
  const [user, setUser] = useState<User>(null)

  return (
    <Modal setOpen={setOpen}>
      <LoginWrapper>
        {!numberSubmitted && (
          <>
            <h2>Login</h2>

            <StartLogin
              setMethodId={setMethodId}
              setNumberSubmitted={setNumberSubmitted}
              setRequiresSetup={setRequiresSetup}
              setUser={setUser}
            />
          </>
        )}

        {numberSubmitted && !loginComplete && (
          <>
            <h2>Verify</h2>
            <FinishLogin
              methodId={methodId}
              requiresSetup={requiresSetup}
              setAuthChecked={setAuthChecked}
              setLoginComplete={setLoginComplete}
              setOpen={setOpen}
              setShouldFetch={setShouldFetch}
            />
          </>
        )}

        {loginComplete && requiresSetup && (
          <>
            <h2>Account Setup</h2>
            <AccountSetup setOpen={setOpen} user={user} />
          </>
        )}
      </LoginWrapper>
    </Modal>
  )
}

export default LoginModal
