import { type Theme } from '@emotion/react'

const voucciTheme: Theme = {
  colors: {
    primary: '#FF7334',
    secondary: '#DEF0FE',
    light: '#f7f8f8',
    text: '#585554',
    textDark: '#1A1918',
    textLight: '#A3A3A3',
    white: 'white',

    stars: {
      empty: '#E0E0E0',
      filled: '#50A3E3',
    },
  },
}

export enum ModuleThemes {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
}

export const moduleThemeMap = {
  [ModuleThemes.Default]: {
    backgroundColor: voucciTheme.colors.white,
    color: voucciTheme.colors.text,
    headingColor: voucciTheme.colors.textDark,
    linkColor: voucciTheme.colors.primary,
  },
  [ModuleThemes.Primary]: {
    backgroundColor: voucciTheme.colors.primary,
    color: voucciTheme.colors.white,
    headingColor: voucciTheme.colors.white,
    linkColor: voucciTheme.colors.white,
  },
  [ModuleThemes.Secondary]: {
    backgroundColor: voucciTheme.colors.light,
    color: voucciTheme.colors.text,
    headingColor: voucciTheme.colors.textDark,
    linkColor: voucciTheme.colors.primary,
  },
}

export default voucciTheme
