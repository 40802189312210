import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import {
  AvatarWrapper,
  HeaderWrapper,
  InlineButton,
  LinksWrapper,
  Logo,
  LogoWrapper,
} from '@components/page/header/style'
import {
  ButtonSize,
  ButtonTheme,
  MinimalButton,
  PrimaryButton,
} from '@components/core/buttons'
import Link from 'next/link'
import { useVoucciState } from '@context/state'

interface HeaderProps {
  logout: () => Promise<void>
  setLocationSwitcherOpen: Dispatch<SetStateAction<boolean>>
  setLoginModalOpen: Dispatch<SetStateAction<boolean>>
  setProfileEditorVisible: Dispatch<SetStateAction<boolean>>
}

const Header: FC<HeaderProps> = ({
  logout,
  setLocationSwitcherOpen,
  setLoginModalOpen,
  setProfileEditorVisible,
}) => {
  const voucciState = useVoucciState()
  const [locationText, setLocationText] = useState<string>('')

  const updateLocationText = (hovered: boolean) => {
    let locationText: string

    if (voucciState.location && !hovered) {
      locationText = `${voucciState.location.locale.city}, ${voucciState.location.locale.state}`
    } else {
      locationText = 'Change Location'
    }

    setLocationText(locationText)
  }

  useEffect(() => {
    if (!locationText && voucciState.location) {
      updateLocationText(false)
    }
  }, [voucciState, locationText])

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Link href="/">
          <Logo src="/images/voucci-logo.svg" alt="voucci" />
        </Link>
      </LogoWrapper>
      <LinksWrapper>
        <Link href="/about">About</Link>

        <MinimalButton
          onClick={(e) => setLocationSwitcherOpen(true)}
          size={ButtonSize.Auto}
        >
          {locationText || 'Change Location'}
        </MinimalButton>

        {voucciState.user && (
          <>
            <InlineButton onClick={(e) => setProfileEditorVisible(true)}>
              <AvatarWrapper>
                {voucciState?.user?.avatar && (
                  <img src={voucciState.user.avatar} />
                )}
                {!voucciState?.user?.avatar && (
                  <img src="/icons/icon-user.svg" />
                )}
              </AvatarWrapper>
            </InlineButton>
          </>
        )}
        {!voucciState.user && (
          <>
            <PrimaryButton
              color={ButtonTheme.White}
              onClick={(e) => setLoginModalOpen(true)}
            >
              Sign In
            </PrimaryButton>
          </>
        )}
      </LinksWrapper>
    </HeaderWrapper>
  )
}

export default Header
