import { Dispatch, FC } from 'react'
import {
  ModalBackground,
  ModalCloseButton,
  ModalContainer,
  ModalWrapper,
} from './style'

interface ModalProps {
  children?: React.ReactNode
  setOpen?: Dispatch<React.SetStateAction<boolean>>
}

const Modal: FC<ModalProps> = ({ children = null, setOpen = () => {} }) => {
  return (
    <ModalContainer>
      <ModalBackground onClick={() => setOpen(false)} />
      <ModalWrapper>
        <ModalCloseButton onClick={() => setOpen(false)} />
        {children}
      </ModalWrapper>
    </ModalContainer>
  )
}

export default Modal
