import { Dispatch, FC, SetStateAction, useState } from 'react'
import { ButtonWrapper, FormWrapper } from '../style'
import { Form } from '@components/core/forms'
import { Input } from '@components/core/forms/input'
import { updateMe } from '@lib/api/v1/users'
import {
  ButtonSize,
  ButtonTheme,
  PrimaryButton,
} from '@components/core/buttons'
import { User } from '@models/users'

interface AccountSetupProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  user: User
}

const AccountSetup: FC<AccountSetupProps> = ({ setOpen, user }) => {
  const [firstName, setFirstName] = useState<string>(user.firstName || '')
  const [lastName, setLastName] = useState<string>(user.lastName || '')

  const submitAccountSetup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      await updateMe({ firstName, lastName })
      setOpen(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <FormWrapper>
      <Form onSubmit={submitAccountSetup}>
        <Input
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First name"
          type="text"
          value={firstName}
        />
        <Input
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Last name"
          type="text"
          value={lastName}
        />

        <ButtonWrapper>
          <PrimaryButton
            color={ButtonTheme.Primary}
            size={ButtonSize.Large}
            type="submit"
          >
            Submit
          </PrimaryButton>
        </ButtonWrapper>
      </Form>
    </FormWrapper>
  )
}

export default AccountSetup
