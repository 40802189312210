import { User } from '@models/users'

export const getMe = async (): Promise<User | null> => {
  const response = await fetch(`/api/v1/users/me`, {
    method: 'GET',
    credentials: 'include',
  })

  if (!response.ok) {
    return null
  }

  return response.json()
}

export const updateAvatar = async (avatar: string): Promise<User> => {
  const response = await fetch(`/api/v1/users/me/avatar`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ avatar }),
  })

  return response.ok ? response.json() : null
}

export const updateMe = async (user: Partial<User>): Promise<User | null> => {
  const response = await fetch(`/api/v1/users/me`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })

  if (!response.ok) {
    return null
  }

  return response.json()
}
