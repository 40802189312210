import styled from '@emotion/styled'

export enum ButtonSize {
  Auto = 'auto',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonTheme {
  Primary = 'primary',
  White = 'white',
}

interface ButtonProps {
  color?: ButtonTheme
  size?: ButtonSize
}

export const MinimalButton = styled.button<ButtonProps>(
  {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '30px',
    borderStyle: 'solid',
    borderWidth: '2px',
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 600,
    justifyContent: 'center',
    justifyItems: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  ({ color = ButtonTheme.White, size = ButtonSize.Auto, theme }) => {
    let sizeValue = 'auto'

    if (size === ButtonSize.Large) {
      sizeValue = '60px'
    } else if (size === ButtonSize.Medium) {
      sizeValue = '45px'
    }

    let paddingValue = '8px 16px'

    if (size === ButtonSize.Large) {
      paddingValue = '16px 32px'
    } else if (size === ButtonSize.Medium) {
      paddingValue = '12px 24px'
    }

    return {
      color:
        color === ButtonTheme.White ? theme.colors.white : theme.colors.primary,
      borderColor:
        color === ButtonTheme.White ? theme.colors.white : theme.colors.primary,
      height: sizeValue,
      padding: paddingValue,
    }
  },
)

export const PrimaryButton = styled.button<ButtonProps>(
  {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'blue',
    border: 'none',
    borderRadius: '30px',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 600,
    justifyContent: 'center',
    justifyItems: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  ({ color = ButtonTheme.Primary, size = ButtonSize.Auto, theme }) => {
    let sizeValue = 'auto'

    if (size === ButtonSize.Large) {
      sizeValue = '60px'
    } else if (size === ButtonSize.Medium) {
      sizeValue = '45px'
    }

    let paddingValue = '8px 16px'

    if (size === ButtonSize.Large) {
      paddingValue = '16px 32px'
    } else if (size === ButtonSize.Medium) {
      paddingValue = '12px 24px'
    }

    return {
      backgroundColor:
        color === ButtonTheme.White ? theme.colors.white : theme.colors.primary,
      color:
        color === ButtonTheme.White ? theme.colors.primary : theme.colors.white,
      borderColor:
        color === ButtonTheme.White ? theme.colors.primary : theme.colors.white,
      height: sizeValue,
      padding: paddingValue,
    }
  },
)
