import styled from '@emotion/styled'
import { lighten } from 'polished'
import voucciTheme from '@lib/theme'

export const Input = styled.input(
  {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '30px',
    fontFamily: 'bryant-web, sans-serif',
    fontSize: '16px',
    height: '60px',
    letterSpacing: '1px',
    outline: 'none',
    padding: '10px 30px',
    width: '100%',
  },
  ({ theme = voucciTheme }) => ({
    backgroundColor: theme.colors.white,
    borderColor: lighten(0.2, theme.colors.textLight),
  }),
)
