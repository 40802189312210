import { FC } from 'react'
import { Column, FooterWrapper, List, ListItem, Logo, Row } from './style'
import Link from 'next/link'

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <Row>
        <Column>
          <Logo src="/images/voucci-logo.svg" alt="Voucci" />
        </Column>
        <Column>
          <List>
            <ListItem>
              <h3>Links</h3>
            </ListItem>
            <ListItem>
              <Link href="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link href="/about">About</Link>
            </ListItem>
            <ListItem>
              <Link href="/contact">Contact</Link>
            </ListItem>
            <ListItem>
              <Link href="/terms">Terms of Service</Link>
            </ListItem>
            <ListItem>
              <Link href="/privacy">Privacy Policy</Link>
            </ListItem>
          </List>
        </Column>
        <Column>
          <List>
            <ListItem>
              <h3>Social</h3>
            </ListItem>
            <ListItem>
              <Link href="/login">Instagram</Link>
            </ListItem>
            <ListItem>
              <Link href="/register">Facebook</Link>
            </ListItem>
            <ListItem>
              <Link href="/register">LinkedIn</Link>
            </ListItem>
            <ListItem>
              <Link href="/register">YouTube</Link>
            </ListItem>
          </List>
        </Column>
      </Row>
      <Row>
        <p style={{ fontSize: '16px', textAlign: 'center', width: '100%' }}>
          Made with ❤️ in Oakland, CA.
        </p>
        <p style={{ fontSize: '14px', textAlign: 'center', width: '100%' }}>
          &copy; {new Date().getFullYear()} <Link href="/">Voucci</Link>. All
          rights reserved.
        </p>
      </Row>
    </FooterWrapper>
  )
}

export default Footer
