export const getPhoneCode = async (): Promise<string> => {
  const ip = await getIp()

  const res = await fetch(`https://ipapi.co/${ip}/country_calling_code/`)

  const data = await res.text()

  return data
}

export const getIp = async (): Promise<string> => {
  const res = await fetch('https://api.ipify.org?format=json')
  const data = await res.json()

  return data.ip
}
