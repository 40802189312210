import { createContext, useContext } from 'react'
import type { VoucciState } from 'types'

const VoucciStateContext = createContext<VoucciState | undefined>(undefined)

export const VoucciStateContextProvider = VoucciStateContext.Provider

export const useVoucciState = () => {
  const state = useContext(VoucciStateContext)

  return state
}

export default VoucciStateContext
