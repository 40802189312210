import styled from '@emotion/styled'
import { mediaQueryStrings } from '@lib/media-queries'
import {
  FlexAlign,
  FlexDirection,
  FlexWrap,
  ModuleHeight,
  type ModuleProps,
} from './index'
import { ModuleThemes, moduleThemeMap } from '@lib/theme'

export const ModuleWrapper = styled.section<ModuleProps>(
  {
    display: 'flex',
    width: '100%',
  },
  ({
    alignItems = FlexAlign.Center,
    colorScheme = ModuleThemes.Default,
    flexDirection = FlexDirection.Row,
    flexWrap = FlexWrap.Wrap,
    height = ModuleHeight.Default,
    justifyContent = FlexAlign.Center,
  }) => {
    const moduleTheme = moduleThemeMap[colorScheme]

    return {
      alignItems,
      backgroundColor: moduleTheme.backgroundColor,
      color: moduleTheme.color,
      flexDirection,
      flexWrap,
      justifyContent,

      [`${mediaQueryStrings.mobile}`]: {
        padding:
          height === ModuleHeight.Naked
            ? '0px 15px'
            : height === ModuleHeight.Slim
            ? '30px 15px'
            : '15px 15px',
      },
      [`${mediaQueryStrings.tablet}`]: {
        padding:
          height === ModuleHeight.Naked
            ? '0px 30px'
            : height === ModuleHeight.Slim
            ? '45px 30px'
            : '45px 60px',
      },
      [`${mediaQueryStrings.desktop}`]: {
        padding:
          height === ModuleHeight.Naked
            ? '0px 60px'
            : height === ModuleHeight.Slim
            ? '60px 60px'
            : '45px 60px',
      },

      'h1, h2, h3, h4, h5': {
        color: moduleTheme.headingColor,
      },
    }
  },
)
