import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'
import voucciTheme from '@lib/theme'

export const AvatarEditorWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center',
})

export const AvatarWrapper = styled.div(
  mediaQueries({
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '150px',
    jusitfyItems: 'center',
    overflow: 'hidden',
    width: '150px',

    img: {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
    },
  }),
  ({ theme = voucciTheme }) => ({
    backgroundColor: theme.colors.light,
  }),
)

export const FilePicker = styled.input({
  height: 0,
  visibility: 'hidden',
  width: 0,
})
