import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'

export const ContentWrapper = styled.div(
  mediaQueries({
    paddingTop: ['150px', '150px', '150px', '75px'],
  }),
)

const PageWrapper = styled.div({
  height: 'auto',
  margin: 0,
  padding: 0,
  width: '100%',
})

export default PageWrapper
