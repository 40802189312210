import { User } from '@models/users'
import { FinishAuthResponse } from 'types'

export const smsStart = async (
  phoneNumber: string,
): Promise<{ methodId: string; user: User }> => {
  const response = await fetch('/api/v1/sms/start', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ phoneNumber }),
  })

  if (!response.ok) {
    throw new Error('Failed to start SMS')
  }

  return (await response.json()) as { methodId: string; user: User }
}

export const smsFinish = async (
  code: string,
  methodId: string,
): Promise<FinishAuthResponse> => {
  const response = await fetch('/api/v1/sms/finish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code, methodId }),
  })

  if (!response.ok) {
    throw new Error('Failed to verify SMS')
  }

  return (await response.json()) as FinishAuthResponse
}
