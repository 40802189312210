import styled from '@emotion/styled'

export const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '30px',
})

export const FormWrapper = styled.div({
  marginTop: '30px',
  width: '100%',
})
