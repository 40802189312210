import {
  ButtonSize,
  ButtonTheme,
  MinimalButton,
  PrimaryButton,
} from '@components/core/buttons'
import { Form } from '@components/core/forms'
import { Input } from '@components/core/forms/input'
import Modal from '@components/core/modal'
import { useVoucciState } from '@context/state'
import { updateMe } from '@lib/api/v1/users'
import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { VoucciState } from 'types'
import { ButtonWrapper, InputWrapper, ProfileEditorWrapper } from './style'
import { FormWrapper } from '@components/auth/login/style'
import { Category } from '@models/categories'
import MultiSelect, {
  MultiSelectOption,
} from '@components/core/forms/multi-select'
import Label from '@components/core/forms/label'
import AvatarEditor from './avatar-editor'

interface ProfileEditorProps {
  categories: Category[]
  logout: () => Promise<void>
  setOpen: Dispatch<SetStateAction<boolean>>
  setState: (newState: Partial<VoucciState>) => void
}

const ProfileEditor: FC<ProfileEditorProps> = ({
  categories: reviewCategories,
  logout,
  setOpen,
  setState,
}) => {
  const voucciState = useVoucciState()

  const [categories, setCategories] = useState<MultiSelectOption[]>([])
  const [categoryOptions, setCategoryOptions] = useState<MultiSelectOption[]>(
    [],
  )
  const [firstName, setFirstName] = useState<string>(
    voucciState.user.firstName || '',
  )
  const [lastName, setLastName] = useState<string>(
    voucciState.user.lastName || '',
  )

  const handleLogout = async () => {
    await logout()
    setOpen(false)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const categoryIds = categories.map((category) => category.value)

    const user = await updateMe({
      categories: categoryIds,
      firstName,
      lastName,
    })

    setState({
      user,
    })
    setOpen(false)
  }

  useEffect(() => {
    if (
      reviewCategories &&
      reviewCategories.length &&
      !categoryOptions.length
    ) {
      const options = reviewCategories.map((category) => ({
        name: category.name,
        value: category.id,
      }))

      setCategoryOptions(options)
    }
  }, [reviewCategories, categoryOptions])

  useEffect(() => {
    if (
      voucciState &&
      voucciState.user &&
      categoryOptions &&
      categoryOptions.length
    ) {
      const selectedCategories = (voucciState.user.categories || []).map(
        (category) => {
          const option = categoryOptions.find(
            (option) => option.value === category,
          )

          if (option) {
            return option
          }
        },
      )

      setCategories(selectedCategories)
    }
  }, [categoryOptions, voucciState])

  return (
    <Modal setOpen={setOpen}>
      <ProfileEditorWrapper>
        <h3>Profile Editor</h3>

        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <AvatarEditor setState={setState} />

            <InputWrapper>
              <Label>First name</Label>
              <Input
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
                value={firstName}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Last name</Label>
              <Input
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
                value={lastName}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>Expert cuisines</Label>
              <MultiSelect
                max={2}
                options={categoryOptions}
                selected={categories}
                setSelected={setCategories}
              />
            </InputWrapper>

            <ButtonWrapper>
              <MinimalButton
                color={ButtonTheme.Primary}
                onClick={handleLogout}
                size={ButtonSize.Large}
              >
                Logout
              </MinimalButton>

              <PrimaryButton size={ButtonSize.Large} type="submit">
                Save
              </PrimaryButton>
            </ButtonWrapper>
          </Form>
        </FormWrapper>
      </ProfileEditorWrapper>
    </Modal>
  )
}

export default ProfileEditor
