import { Dispatch, FC, FormEvent, SetStateAction, useState } from 'react'
import Modal from '../../core/modal'
import { Form } from '../../core/forms'
import { Input } from '../../core/forms/input'
import { ButtonSize, PrimaryButton } from '../../core/buttons'
import { geocodeAddress } from '@lib/api/v1/maps'
import { setLastKnownLocation } from '@lib/voucci/client'
import { VoucciState } from 'types'
import { useVoucciState } from '@context/state'
import { ButtonWrapper, FormWrapper } from './style'

interface LocationSwitcherProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  setState: (newState: Partial<VoucciState>) => void
}

const LocationSwitcher: FC<LocationSwitcherProps> = ({ setOpen, setState }) => {
  const voucciState = useVoucciState()

  const [locationQuery, setLocationQuery] = useState<string>(
    voucciState.location
      ? `${voucciState.location.locale.city}, ${voucciState.location.locale.state}`
      : '',
  )

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const location = await geocodeAddress(locationQuery)
    setLastKnownLocation(location)
    setState({
      location,
    })
    setOpen(false)
  }

  return (
    <Modal setOpen={setOpen}>
      <div style={{ width: '100%' }}>
        <h3>Change Location</h3>

        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <Input
              onChange={(e) => setLocationQuery(e.target.value)}
              placeholder="City or postal code..."
              type="text"
              value={locationQuery}
            />

            <ButtonWrapper>
              <PrimaryButton size={ButtonSize.Large} type="submit">
                Set Location
              </PrimaryButton>
            </ButtonWrapper>
          </Form>
        </FormWrapper>
      </div>
    </Modal>
  )
}

export default LocationSwitcher
