import styled from '@emotion/styled'

export const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '30px',
  width: '100%',
})

export const FormWrapper = styled.div({
  marginTop: '30px',
  width: '100%',
})

export const InputWrapper = styled.div({
  width: '100%',

  '&:not(:first-of-type)': {
    marginTop: '15px',
  },
})

export const ProfileEditorWrapper = styled.div({
  width: '100%',
})
