import { geocodeCoordinates } from '@lib/api/v1/maps'
import type { StoredLocation } from 'types'

export const getLastKnownLocation = async (): Promise<StoredLocation> => {
  return new Promise((resolve, reject) => {
    let storedLocation = localStorage.getItem('lastKnownLocation')

    if (!storedLocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        const location = await setLastKnownLocation(center)

        resolve(location)
      })
    } else {
      resolve(JSON.parse(storedLocation) as StoredLocation)
    }
  })
}

export const setLastKnownLocation = async (
  center: google.maps.LatLngLiteral | StoredLocation,
): Promise<StoredLocation> => {
  let location: StoredLocation

  if (typeof center['center'] !== 'undefined') {
    location = center as StoredLocation
  } else {
    location = await geocodeCoordinates(center as google.maps.LatLngLiteral)
  }

  localStorage.setItem('lastKnownLocation', JSON.stringify(location))

  return location
}
