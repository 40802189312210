import styled from '@emotion/styled'
import voucciTheme from '@lib/theme'
import { lighten } from 'polished'

export const Option = styled.div(
  {
    padding: '15px 30px',
    width: '100%',
  },
  ({ theme = voucciTheme }) => ({
    '&:hover': {
      backgroundColor: lighten(0.3, theme.colors.textLight),
    },
  }),
)

interface OptionsProps {
  open?: boolean
}
export const Options = styled.div<OptionsProps>(
  {
    borderRadius: '30px',
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'flex',
    fontSize: '14px',
    flexWrap: 'wrap',
    justifyContent: 'start',
    marginTop: '5px',
    maxHeight: '240px',
    position: 'relative',
    overflowX: 'scroll',
    overflowY: 'visible',
    width: '100%',
    zIndex: 1,
  },
  ({ open = false, theme = voucciTheme }) => ({
    backgroundColor: theme.colors.white,
    borderColor: lighten(0.2, theme.colors.textLight),
    display: open ? 'flex' : 'none',
  }),
)

export const Prompt = styled.div({
  alignContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  height: '100%',
  fontSize: '14px',
  padding: '15px 30px',
  width: '100%',
})

export const SelectWrapper = styled.div({
  borderRadius: '30px',
  height: '60px',
})

export const SelectedItems = styled.div({
  marginBottom: '15px',
  marginTop: '15px',
  paddingLeft: '15px',
  paddingRight: '15px',
  width: '100%',
})

export const SelectedItem = styled.div(
  {
    alignContent: 'center',
    borderRadius: '15px',
    display: 'inline-flex',
    fontSize: '12px',
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: '1px',
    padding: '5px 30px 5px 15px',
    position: 'relative',
    textTransform: 'uppercase',

    '&:not(:first-of-type)': {
      marginLeft: '5px',
    },

    '&:after': {
      alignContent: 'center',
      content: '"x"',
      cursor: 'pointer',
      display: 'flex',
      height: '10px',
      justifyContent: 'center',
      position: 'absolute',
      right: '7.5px',
      top: '5px',
      width: '10px',
    },
  },
  ({ theme = voucciTheme }) => ({
    backgroundColor: lighten(0.3, theme.colors.textLight),
    color: theme.colors.text,
  }),
)
