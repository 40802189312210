import { Dispatch, FC, SetStateAction, useState } from 'react'
import { ButtonWrapper, FormWrapper } from '../style'
import { Form } from '@components/core/forms'
import { Input } from '@components/core/forms/input'
import { getPhoneCode } from '@lib/device'
import { smsStart } from '@lib/api'
import { ButtonSize, PrimaryButton } from '@components/core/buttons'
import { User } from '@models/users'

interface StartLoginProps {
  setMethodId: Dispatch<SetStateAction<string>>
  setNumberSubmitted: Dispatch<SetStateAction<boolean>>
  setRequiresSetup: Dispatch<SetStateAction<boolean>>
  setUser: Dispatch<SetStateAction<User>>
}

const StartLogin: FC<StartLoginProps> = ({
  setMethodId,
  setNumberSubmitted,
  setRequiresSetup,
  setUser,
}) => {
  const [phone, setPhone] = useState<string>('')

  const startSmsLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const phoneRegex = /(\(|\)|\-|\.)/g
      const phoneCode = await getPhoneCode()
      const number = phone.replace(phoneRegex, '')
      const phoneNumber = `${phoneCode}${number}`

      const { methodId, user } = await smsStart(phoneNumber)

      localStorage.setItem('methodId', methodId)
      setMethodId(methodId)

      setNumberSubmitted(true)
      setRequiresSetup(!user.verified)

      setUser(user)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <p>Enter your phone number to login.</p>

      <FormWrapper>
        <Form onSubmit={startSmsLogin}>
          <Input
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            value={phone}
          />

          <ButtonWrapper>
            <PrimaryButton size={ButtonSize.Large} type="submit">
              Send
            </PrimaryButton>
          </ButtonWrapper>
        </Form>
      </FormWrapper>
    </>
  )
}

export default StartLogin
