import { ChangeEvent, FC, FormEvent, MouseEvent, useRef, useState } from 'react'
import { AvatarEditorWrapper, AvatarWrapper, FilePicker } from './style'
import { getMe, updateAvatar } from '@lib/api/v1/users'
import { VoucciState } from 'types'
import { useVoucciState } from '@context/state'

interface AvatarEditorProps {
  setState: (newState: Partial<VoucciState>) => void
}

const AvatarEditor: FC<AvatarEditorProps> = ({ setState }) => {
  const voucciState = useVoucciState()

  const filePickerRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const result = e.target.result as string

        const updatedUser = await updateAvatar(result)

        if (updatedUser && updatedUser.id) {
          setState({
            user: updatedUser,
          })
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const openFilePicker = () => {
    filePickerRef.current.click()
  }

  return (
    <>
      <AvatarEditorWrapper>
        <AvatarWrapper onClick={openFilePicker}>
          <img src={voucciState.user?.avatar || '/images/avatar-default.png'} />
        </AvatarWrapper>
      </AvatarEditorWrapper>

      <FilePicker
        ref={filePickerRef}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleFileChange}
      />
    </>
  )
}

export default AvatarEditor
