import { Category } from '@models/categories'

export const getAllCategories = async (): Promise<Category[]> => {
  const response = await fetch('/api/v1/categories')

  if (response.ok) {
    return response.json()
  }

  return []
}

export const getCategoryById = async (
  id: string,
): Promise<Category | undefined> => {
  const response = await fetch(`/api/v1/categories/${id}`)

  if (response.ok) {
    return response.json()
  }

  return
}
