import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'
import voucciTheme from '@lib/theme'

export const Column = styled.div(
  mediaQueries({
    width: ['100%', '100%', '100%', '50%', 'calc(100%/3)'],

    '&:not(:first-of-type)': {
      marginTop: ['30px', '30px', '30px', '0', '0'],
    },
  }),
)

export const FooterWrapper = styled.footer(
  {
    padding: '60px 30px',

    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  ({ theme = voucciTheme }) => ({
    backgroundColor: theme.colors.textDark,
    color: theme.colors.white,

    a: {
      color: theme.colors.white,
    },
  }),
)

export const List = styled.ul({
  listStyle: 'none',
  margin: 0,
  padding: 0,
})

export const ListItem = styled.li({
  listStyle: 'none',

  '&:not(:first-of-type)': {
    marginTop: '15px',
  },
})

export const Logo = styled.img({
  maxHeight: '45px',
  width: 'auto',
})

export const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',

  '&:not(:first-of-type)': {
    marginTop: '60px',
  },
})
