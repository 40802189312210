import styled from '@emotion/styled'
import voucciTheme from '@lib/theme'
import { lighten } from 'polished'

const Label = styled.label(
  {
    display: 'block',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    marginBottom: '5px',
    paddingLeft: '15px',
    textTransform: 'uppercase',
    width: '100%',
  },
  ({ theme = voucciTheme }) => ({
    color: lighten(0.25, theme.colors.text),
  }),
)

export default Label
