import { StoredLocation } from 'types'

export const geocodeAddress = async (
  address: string,
): Promise<StoredLocation | undefined> => {
  const response = await fetch('/api/v1/maps/geocode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ address }),
  })

  return response.ok ? response.json() : undefined
}

export const geocodeCoordinates = async (
  center: google.maps.LatLngLiteral,
): Promise<StoredLocation | undefined> => {
  const response = await fetch('/api/v1/maps/geocode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ location: center }),
  })

  return response.ok ? response.json() : undefined
}
