import styled from '@emotion/styled'

export const ButtonWrapper = styled.div({
  marginTop: '15px',
})

export const FormWrapper = styled.div({
  marginTop: '30px',
})

export const LoginWrapper = styled.div({
  display: 'block',
  flexGrow: 1,
  height: '100%',
  width: '100%',
})
