import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'

export const ModalCloseButton = styled.div({
  background: 'url(/icons/icon-x.svg) no-repeat center',
  backgroundSize: 'contain',
  cursor: 'pointer',
  filter:
    'brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(0%) hue-rotate(170deg) brightness(84%) contrast(86%)',
  height: '15px',
  opacity: 0.5,
  position: 'absolute',
  right: '30px',
  top: '30px',
  transition: 'filter 0.25s ease-in, opacity 0.25s ease-in',
  width: '15px',
  zIndex: 100,

  '&:hover': {
    filter:
      'brightness(0) saturate(100%) invert(31%) sepia(7%) saturate(213%) hue-rotate(329deg) brightness(96%) contrast(83%)',
    opacity: 1,
  },
})

export const ModalBackground = styled.div({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
})

export const ModalContainer = styled.div({
  display: 'flex',
  height: '100vh',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '100vw',
  zIndex: 100,
})

export const ModalWrapper = styled.div(
  mediaQueries({
    alignItems: 'flex-start',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    maxHeight: '90%',
    padding: '30px',
    position: 'relative',
    overflowY: 'scroll',
    width: ['90%', '90%', '80%', '60%', '40%'],
  }),
  ({ theme }) => ({
    backgroundColor: theme.colors.white,
  }),
)
