import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'
import voucciTheme from '@lib/theme'

export const HeaderWrapper = styled.header(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
    height: ['150px', '150px', '150px', '75px'],
    justifyContent: 'space-between',
    left: 0,
    padding: '15px',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 99,

    a: {
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '1px',
      padding: '5px 10px',
      textTransform: 'uppercase',

      '&:not(:first-of-type)': {
        marginLeft: ['15px', '15px', '30px'],
      },
    },
    button: {
      '&:not(:first-child)': {
        marginLeft: ['15px', '15px', '30px'],
      },
    },
  }),
  ({ theme }) => ({
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,

    a: {
      color: theme.colors.white,
      textDecoration: 'none',
    },
  }),
)

interface AvatarWrapperProps {
  inverted?: boolean
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>(
  mediaQueries({
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '34px',
    jusitfyItems: 'center',
    overflow: 'hidden',
    width: '34px',

    img: {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
    },
  }),
  ({ inverted, theme = voucciTheme }) => ({
    backgroundColor: theme.colors.light,

    img: {
      filter: inverted ? 'invert(1)' : 'none',
    },
  }),
)

export const InlineButton = styled.div(
  mediaQueries({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '1px',
    marginLeft: ['15px', '15px', '30px'],
    padding: '5px 10px',
    textTransform: 'uppercase',
  }),
)

export const LinksWrapper = styled.div(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    marginLeft: ['0', '0', '30px'],
    justifyContent: ['center', 'center', 'center', 'flex-end'],
    width: '100%',

    div: {
      '&:not(:first-of-type)': {
        marginLeft: ['15px', '15px', '30px'],
      },
    },
  }),
)

export const Logo = styled.img({
  height: '45px',
  maxHeight: '45px',
  width: 'auto',
})

export const LogoWrapper = styled.div(
  mediaQueries({
    flexGrow: 0,
    margin: ['auto', 'auto', 'auto', 0],
  }),
)
