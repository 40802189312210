import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Option,
  Options,
  Prompt,
  SelectWrapper,
  SelectedItem,
  SelectedItems,
} from './style'
import { Input } from '../input'

export interface MultiSelectOption {
  name: string
  value: string
}

interface MultiSelectProps {
  max?: number
  options: MultiSelectOption[]
  selected: MultiSelectOption[]
  setSelected: Dispatch<SetStateAction<MultiSelectOption[]>>
}

const MultiSelect: FC<MultiSelectProps> = ({
  max = 1000,
  options,
  selected,
  setSelected,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [filteredOptions, setFilteredOptions] =
    useState<MultiSelectOption[]>(options)
  const [optionsOpen, setOptionsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleDeselect = (option: MultiSelectOption) => {
    setSelected(selected.filter((item) => item.value !== option.value))
  }

  const handleSearch = (e: FormEvent<HTMLInputElement>) => {
    const term = e.currentTarget.value
    setSearchTerm(term)

    if (searchTerm.length > 0) {
      setFilteredOptions(
        options.filter((option) =>
          option.name.toLowerCase().includes(term.toLowerCase()),
        ),
      )
    }
  }

  const handleSelect = (option: MultiSelectOption) => {
    if (selected.length + 1 <= max) {
      if (selected.includes(option)) {
        setSelected(selected.filter((item) => item.value !== option.value))
      } else {
        setSelected([...selected, option])
      }
    }
  }

  useEffect(() => {
    if (max && selected && selected.length === max) {
      setOptionsOpen(false)
      setSearchTerm('')

      if (inputRef && inputRef.current) {
        inputRef.current.blur()
      }
    }
  }, [max, selected])

  useEffect(() => {
    if (
      options &&
      options.length &&
      filteredOptions &&
      !filteredOptions.length &&
      !searchTerm.length
    ) {
      setFilteredOptions(options)
    }
  }, [options, filteredOptions])

  return (
    <>
      <SelectedItems>
        {selected.map((item) => (
          <SelectedItem
            key={item.value}
            onClick={() => {
              handleDeselect(item)
            }}
          >
            {item.name}
          </SelectedItem>
        ))}
      </SelectedItems>

      <SelectWrapper>
        <Input
          ref={inputRef}
          type="text"
          onBlur={() => setOptionsOpen(false)}
          onChange={handleSearch}
          onFocus={(e) => {
            if (selected.length === max) {
              e.preventDefault()
              e.stopPropagation()
              e.target.blur()
              return
            }

            setOptionsOpen(true)
          }}
          placeholder={
            selected.length === max
              ? `Max met. Remove an item to select again.`
              : `Select up to ${max} ${max === 1 ? 'item' : 'items'}...`
          }
          value={searchTerm}
        />
        <Options open={optionsOpen}>
          {filteredOptions.map((option) => (
            <Option
              key={option.value}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()

                handleSelect(option)
              }}
            >
              {option.name}
            </Option>
          ))}
        </Options>
      </SelectWrapper>
    </>
  )
}

export default MultiSelect
