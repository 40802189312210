import { type FC, type ReactNode } from 'react'
import { ModuleThemes } from '@lib/theme'

import { ModuleWrapper } from './style'

export enum FlexAlign {
  Start = 'flex-start',
  Center = 'center',
  End = 'flex-end',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
}

export enum FlexDirection {
  Row = 'row',
  Column = 'column',
}

export enum FlexJustify {
  Start = 'flex-start',
  Center = 'center',
  End = 'flex-end',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
}

export enum FlexWrap {
  Wrap = 'wrap',
  NoWrap = 'nowrap',
}

export enum ModuleHeight {
  Default = 'default',
  Naked = 'naked',
  Slim = 'slim',
}

export interface ModuleProps {
  alignItems?: FlexAlign
  children?: ReactNode
  colorScheme?: ModuleThemes
  height?: ModuleHeight
  flexDirection?: FlexDirection
  flexWrap?: FlexWrap
  justifyContent?: FlexJustify
}

const Module: FC<ModuleProps> = ({ children = null, ...rest }) => {
  return <ModuleWrapper {...rest}>{children}</ModuleWrapper>
}

export default Module
